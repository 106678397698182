






























































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { ValidationObserver } from 'vee-validate'

import ProgressBar from '@/partials/components/ProgressBar.vue'
import DataCard from '@/partials/components/DataCard.vue'
import UserAttachments from '@/partials/tables/associations/Attachments.vue'
import AssociationsMenu from '@/layouts/AssociationsMenu.vue'
import TablePagination from '@/partials/tables/Pagination.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import UserExams from '@/partials/tables/associations/Exams.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import ServiceUserDiaries from '@/partials/tables/associations/ServiceUserDiaries.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    ServiceUserDiaries,
    ProgressBar,
    DataCard,
    UserExams,
    TablePagination,
    UserAttachments,
    AssociationsMenu,
    PasswordConfirmField,
    ValidationObserver
  }
})

export default class AssociationsIndex extends Mixins(PermissionsMixin, FlashMessageMixin) {
  @sessionModule.Getter isCommonUser!: boolean
  @sessionModule.Getter state!: Record<string, any>

  data = []
  filteredData = []
  associationsTotal = 0
  appName = process.env.VUE_APP_NAME
  modalIsOpened = false
  showEligibleSection = true
  showAlreadyRequestedSection = false

  form: Record<string, any> = {
    notes: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  association = {
    points: 0,
    pointsCurrent: 0,
    bestScore: 0,
    attachments: []
  }

  fetched = false

  created () {
    if (this.isCommonUser) {
      axios.get(`/user/association/${this.state.user.association.id}`)
        .then(response => {
          this.association = camelCaseKeys(response.data.data, { deep: true })
          this.form.notes = `Solicito a análise da minha efetivação a Membro da SBQ por ter obtido ${response.data.data.best_score} pontos no Exame Admissional e ${response.data.data.points_current}/${response.data.data.points} pontos de documentos comprobatórios necessários para minha categoria.`
          this.showEligibleSection = camelCaseKeys(response.data.data.is_eligible, { deep: true })
          this.showAlreadyRequestedSection = !!response.data.data.requested_at
        })
        .finally(() => { this.fetched = true })
    } else {
      this.fetchAssociationsPending()
    }
  }

  submit () {
    axios.patch(`/user/association/${this.state.user.association.id}/request`, this.form)
      .then((response) => {
        const flashMessage = { message: response.data.data, isSuccess: true }
        this.setFlashMessage(flashMessage)
        this.modalIsOpened = false
        this.showEligibleSection = false
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  fetchAssociationsPending () {
    axios.get('/association/attachment/pending')
      .then(response => {
        this.association.attachments = camelCaseKeys(response.data.data, { deep: true })
        this.fetched = true
      })
      .catch(err => {
        console.log(err.response.data?.error)
        this.association.attachments = []
      })
      .finally(() => { this.fetched = true })
  }

  @Watch('$route.query')
  onRouteChange () {
    if (this.$route.query.pending) {
      this.fetchAssociationsPending()
    } else {
      this.fetchAssociations()
    }
  }

  fetchAssociations (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('/user/association', {
      params: {
        'page[number]': page,
        ...perPageSnake,
        ...this.$route.query
      }
    })
      .then(response => {
        this.filteredData = this.data = camelCaseKeys(response.data.data, { deep: true })
        this.associationsTotal = response.data.total
      })
  }

  search (searchString: string) {
    this.filteredData = this.data.filter((associate: Record<string, any>) => {
      if (associate.user === null) return associate
      let { email, name } = associate.user

      email = email.toLowerCase()
      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (email.includes(searchString) || name.includes(searchString)) {
        return associate
      }
    })
  }

  get associationPointsPercentage () {
    return (this.association.pointsCurrent / this.association.points) * 100
  }

  onPageChange (pagination: Pagination) {
    this.fetchAssociations(pagination.page, pagination.length)
  }

  eligibilityText (isEligible: boolean) {
    return isEligible ? 'Sim' : 'Não'
  }

  toggleModal () {
    this.modalIsOpened = !this.modalIsOpened
  }

  clearForm () {
    this.form = {
      notes: '',
      confirm: '',
      errors: {
        confirm: ''
      }
    }
  }
}
